import React from 'react';
import { generatePath } from 'react-router';
import styled from 'styled-components/macro';
import Icons from '../icons';
import StylelessLink from '../styleless-link';
import { Paths } from '../../routes';

const Container = styled(StylelessLink)`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem;
`;

const Comments = styled.div.attrs(() => ({
  className: 'text-wrap text-break',
}))`
`;

const ReactionCardComments: React.FC<{
  reactionId: number;
  comments: number;
}> = ({
  reactionId,
  comments,
}) => {
  return (
    <Container
      to={generatePath(Paths.ReactionDetails, {
        id: reactionId,
      })}
    >
      <IconWrapper>
        <Icons.ReactionComment />
      </IconWrapper>
      <Comments>
        {comments > 0 ? (
          `${comments} comments...`
        ) : (
          'Add comment'
        )}
      </Comments>
    </Container>
  );
};

export default ReactionCardComments;
