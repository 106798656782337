import React from 'react';
import styled from 'styled-components/macro';
import { Icons, StylelessButton } from '../../components';
import { useShowSpoilerWarning } from '../../hooks';

const Container = styled.div`
  display: flex;
  align-items: center;

  padding: 0.5rem 1rem;
  background-color: #E13257;
`;

const SpoilerIcon = styled(Icons.CommentSpoiler)`
  margin-right: 1rem;
`;

const Message = styled.div`
  color: #FFF;
  flex-grow: 1;
`;

const NormalText = styled.span`
`;

const StrongText = styled.span`
  font-weight: 800;
`;

const ProductionDiscussionSpoiler: React.FC = () => {
  const [showWarning, closeWarning] = useShowSpoilerWarning();

  return showWarning ? (
    <Container>
      <SpoilerIcon />
      <Message>
        <NormalText>
          {'Beware of '}
        </NormalText>
        <StrongText>
          Spoilers!
        </StrongText>
      </Message>
      <StylelessButton
        onClick={closeWarning}
      >
        <Icons.Close />
      </StylelessButton>
    </Container>
  ) : null;
};

export default ProductionDiscussionSpoiler;
