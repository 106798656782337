import { useState } from 'react';

/**
 * TODO: Find a way (maybe CSS in JS?) to solve the need
 * to hardcode CSS width/height values here
 */
const usePosterHeight = (initialHeight = 311) => {
  const [posterHeight, setPosterHeight] = useState<number>(initialHeight);

  const calculatePosterHeight = (posterWidth: number) => {
    const posterHeightRatio = 0.942424;
    const minPosterHeight = 250;
    return Math.round(Math.max(posterWidth * posterHeightRatio, minPosterHeight));
  };

  const onWidthChange = (posterWidth: number) => {
    const newPosterHeight = calculatePosterHeight(posterWidth);
    if (newPosterHeight !== posterHeight) setPosterHeight(newPosterHeight);
  };

  return [posterHeight, onWidthChange] as const;
};

export default usePosterHeight;
