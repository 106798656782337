import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router';
import styled from 'styled-components/macro';
import { Paths } from '../../routes';
import Pages from '..';
import ClientAppNavigation from './client-app-navigation';
import { IosPopup } from '../../components';
import { useMobileAppPopup } from '../../hooks';

const Container = styled.div<{
  $banner: boolean;
}>`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    ${(props) => (props.$banner ? `
      & .drawerPaper {
        top: 3rem;
      }
    ` : '')}
  }
`;

const Content = styled.div`
  width: 100%;
`;

const ClientApp: React.FC = () => {
  const { open, closePopup } = useMobileAppPopup();

  return (
    <Container
      $banner={false}
    >
      <IosPopup
        onClose={closePopup}
        open={open}
      />
      <ClientAppNavigation>
        <Content>
          <Switch>
            <Route path={Paths.Home}>
              <Pages.Home />
            </Route>
            <Route path={Paths.ProductionDiscussion}>
              <Pages.ProductionDiscussion />
            </Route>
            <Route path={Paths.ProductionDetails}>
              <Pages.ProductionDetails />
            </Route>
            <Route path={Paths.ReactionDetails}>
              <Pages.ReactionDetails />
            </Route>
            <Route path={Paths.About}>
              <Pages.About />
            </Route>
            <Route path={Paths.Auth}>
              <Pages.Auth />
            </Route>
            <Route path={Paths.Default}>
              <Redirect to={Paths.Home} />
            </Route>
          </Switch>
        </Content>
      </ClientAppNavigation>
    </Container>
  );
};

export default ClientApp;
