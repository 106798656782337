import React from 'react';
import styled from 'styled-components/macro';
import {
  NumericDropdown,
  StylelessButton,
  Icons,
} from '../../components';
import { ArrayUtils } from '../../utils';
import { Nullable } from '../../utils/type-utils';

const Container = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #F3F1FD;
  border-bottom: 1px solid #F3F1FD;
  width: 100%;
`;

const Arrow = styled(Icons.ChevronDown)`
  && {
    right: 1rem;
  }
`;

const SeasonSelector = styled(NumericDropdown)`
  height: 100%;
  flex-shrink: 0;
  border-right: 1px solid #F3F1FD;
  font-size: 1rem;
  line-height: 1.5rem;

  && .MuiInputBase-input {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 2.75rem;
  }
`;

const EpisodeSelector = styled.div`
  overflow: auto;
`;

const EpisodePlaceholder = styled.div`
  padding: 0.75rem 1rem;
  color: #767676;
`;

const EpisodeList = styled.div`
  white-space: nowrap;
  overflow: auto;
`;

const EpisodeOption = styled(StylelessButton)`
  border-right: 1px solid #F3F1FD;
  display: inline-block;
  color: #767676;
  padding: 0.75rem 1rem;
`;

const SelectedEpisodeOption = styled(EpisodeOption)`
  color: #795DE7;
  font-weight: 800;
`;

const formatEpisodeNumber = (episode: number) => {
  return `${episode < 10 ? '0' : ''}${episode}`;
};

type Season = {
  episodes: number;
  seasonNumber: number;
};

const ProductionDetailsFilters: React.FC<{
  seasons?: Season[];
  selectedSeason?: Nullable<Season>;
  selectedEpisodeNumber?: number;
  onSeasonChange: (seasonNumber: number) => void;
  onEpisodeChange: (episodeNumber: number) => void;
}> = ({
  seasons,
  selectedSeason,
  selectedEpisodeNumber,
  onSeasonChange,
  onEpisodeChange,
}) => {
  return (
    <Container>
      <SeasonSelector
        onChange={(value) => onSeasonChange(value)}
        value={selectedSeason?.seasonNumber || 0}
        Arrow={Arrow}
      >
        <option value={0}>
          All Seasons
        </option>
        {seasons?.map((season) => {
          return (
            <option key={season.seasonNumber} value={season.seasonNumber}>
              {`Season ${season.seasonNumber}`}
            </option>
          );
        })}
      </SeasonSelector>
      <EpisodeSelector>
        {selectedSeason ? (
          <EpisodeList>
            {ArrayUtils.mapN(selectedSeason.episodes, (index) => (
              selectedEpisodeNumber === index + 1 ? (
                <SelectedEpisodeOption
                  key={index}
                  onClick={() => onEpisodeChange(index + 1)}
                >
                  {`E${formatEpisodeNumber(index + 1)}`}
                </SelectedEpisodeOption>
              ) : (
                <EpisodeOption
                  key={index}
                  onClick={() => onEpisodeChange(index + 1)}
                >
                  {`E${formatEpisodeNumber(index + 1)}`}
                </EpisodeOption>
              )
            ))}
          </EpisodeList>
        ) : (
          <EpisodePlaceholder>
            Episodes: Select Season
          </EpisodePlaceholder>
        )}
      </EpisodeSelector>
    </Container>
  );
};

export default ProductionDetailsFilters;
