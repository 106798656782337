import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { Paths } from '../../routes';
import Pages from '..';

const Home: React.FC = () => {
  return (
    <Switch>
      <Route path={Paths.WhatsPoppin}>
        <Pages.WhatsPoppin />
      </Route>
      <Route path={Paths.TopReactions}>
        <Pages.TopReactions />
      </Route>
      <Route exact path={Paths.Home}>
        <Redirect to={Paths.WhatsPoppin} />
      </Route>
    </Switch>
  );
};

export default Home;
