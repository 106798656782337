import useQueryParams from './use-query-params';

const useIsResetPassword = () => {
  const [{ mode, oobCode }] = useQueryParams();

  return mode === 'resetPassword'
    && typeof oobCode === 'string'
    && !!oobCode;
};

export default useIsResetPassword;
