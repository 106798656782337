import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components/macro';
import { Gif } from '@giphy/react-components';
import { useGiphyGif } from '../../hooks';
import { ReactionType, REACTION_TYPES } from '../../services/reaction-service';
import { Nilable } from '../../utils/type-utils';
import Icons from '../icons';
import StylelessButton from '../styleless-button';

const Container = styled.div`
  padding: 0;
  position: relative;

  & video {
    background-color: #000;
    display: block;
  }
`;

const InnerContainer = styled.div`
  padding: 0;
  position: relative;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0rem;
  bottom: 0rem;
  right: 0rem;
  left: 0rem;
`;

const Play = styled(StylelessButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;

const BottomControls = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
`;

const Volume = styled.div`
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 1rem;
`;

const ProgressBar = styled.div`
  height: 0.125rem;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.36);
`;

const ProgressBarIndicator = styled.div<{ progress: number }>`
  height: 100%;
  background-color: #FFF;
  width: ${(props) => props.progress * 100}%;
`;

const Poster = styled.img`
  width: 100%;
  object-fit: cover;
`;

const GiphyDisplay = styled(Gif)`
  width: unset !important;
  height: unset !important;

  & > div {
    width: unset !important;
    height: unset !important;
  }

  & .giphy-gif-img {
    width: 100% !important;
    height: 100% !important;
  }
`;

const VideoView: React.FC<{
  video: string;
  thumbnail: string;
}> = ({
  video,
  thumbnail,
}) => {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [progress, setProgress] = useState(0);

  return (
    <InnerContainer>
      <ReactPlayer
        width="100%"
        height="auto"
        muted={muted}
        playsinline
        controls={false}
        playing={playing}
        onStart={() => setPlaying(true)}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onEnded={() => setPlaying(false)}
        onProgress={({ played }) => setProgress(played)}
        progressInterval={300}
        url={video}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              poster: thumbnail,
              /**
               * TODO:
               * Ideally we do not want any preload. This is a quick fix
               * so that the player do not shrink when play is pressed.
               * */
              preload: 'metadata',
            },
          },
        }}
      />
      <Controls>
        <Play onClick={() => setPlaying(!playing)}>
          {!playing && (
            <Icons.ReactionPlay />
          )}
        </Play>
        <BottomControls>
          <Volume onClick={() => setMuted(!muted)}>
            {muted ? (
              <Icons.ReactionMuted />
            ) : (
              <Icons.ReactionUnmuted />
            )}
          </Volume>
          <ProgressBarWrapper>
            <ProgressBar>
              <ProgressBarIndicator
                progress={progress}
              />
            </ProgressBar>
          </ProgressBarWrapper>
        </BottomControls>
      </Controls>
    </InnerContainer>
  );
};

const ImageView: React.FC<{
  src: string;
}> = ({
  src,
}) => {
  return (
    <Poster
      src={src}
      title="image-reaction"
    />
  );
};

const GiphyView: React.FC<{
  giphyId: Nilable<string>;
}> = ({ giphyId }) => {
  const gif = useGiphyGif(giphyId);
  return gif ? (<GiphyDisplay gif={gif} width={300} />) : null;
};

const ReactionCardMedia: React.FC<{
  video: string;
  thumbnail: string;
  type: ReactionType;
  format: Nilable<string>;
  giphyId: Nilable<string>;
  className?: string;
}> = ({
  video,
  thumbnail,
  type,
  giphyId,
  className,
}) => {
  return (
    <Container className={className}>
      {type === REACTION_TYPES.VIDEO ? (
        <VideoView
          video={video}
          thumbnail={thumbnail}
        />
      ) : type === REACTION_TYPES.IMAGE || type === REACTION_TYPES.TEXT ? (
        <ImageView src={video} />
      ) : type === REACTION_TYPES.GIPHY ? (
        <GiphyView giphyId={giphyId} />
      ) : null}
    </Container>
  );
};

export default ReactionCardMedia;
