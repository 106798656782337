import usePreparedPages from './use-prepared-pages';
import { Nilable } from '../utils/type-utils';

type Page<PageItem> = Nilable<PageItem[]>;

const usePagesAreLoaded = <PageItem>({
  pageCount,
  pages,
}: {
  pageCount: number;
  pages: Page<PageItem>[];
}) => {
  const preparedPages = usePreparedPages(pages);

  return preparedPages.length >= pageCount;
};

export default usePagesAreLoaded;
