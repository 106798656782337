import React from 'react';
import styled from 'styled-components/macro';
import { NativeSelect } from '@material-ui/core';

const Container = styled(NativeSelect)`
  & select {
    font-weight: 800;

    /**
      Material UI seems to override our global font-family
     */    
    font-family: 'Nunito';
  }

  & option:checked {
    font-weight: 800;
  }

  &.MuiInput-underline:before {
    content: none;
  }
  &.Mui-focused select {
    background-color: unset;
  }
  &.Mui-focused:after {
    content: none;
  }
`;

const Dropdown: React.FC<{
  value?: string | number;
  onChange?: (value: string) => void;
  Arrow?: React.ElementType;
  className?: string;
}> = ({
  value,
  onChange,
  Arrow,
  children,
  className,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <Container
      value={value}
      onChange={handleChange}
      className={className}
      IconComponent={Arrow}
    >
      {children}
    </Container>
  );
};

export default Dropdown;
