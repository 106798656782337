import styled from 'styled-components/macro';

const ReactionCardRoundContainer = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #FFF;
  box-shadow: 0px 16px 16px rgba(64, 48, 141, 0.16);
  border-radius: 16px;

  & > * {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-shrink: 0;
  }
`;

export default ReactionCardRoundContainer;
