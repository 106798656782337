import { PublicApi } from './apis';
import { ApiResponse } from './apis/apis';
import { Nullable } from '../utils/type-utils';

export type CommentModel = {
  id: number;
  userName: string;
  userPortrait: string;
  uploadDate: string;
  content: string;
};

export type ThreadModel = {
  id: number;
  userDisplayName: string;
  uploadDate: string;
  content: string;
  descendantsCount: number;
  children: ThreadModel[];
  likes: number;
};

type ApiCommentModel = {
  id: number;
  content: string;
  time: string;
  userPhotoUrl?: Nullable<string>;
  userDisplayName?: Nullable<string>;
  icon: string;
};

type ApiThreadModel = {
  id: number;
  children_count: string;
  content: string;
  date: string;
  userProviderUuid: string;
  userDisplayName: string;
  depth: number;
  likes: number;
};

const mapThreadsRecursively = (
  threads: ApiThreadModel[],
  depth: number,
): ThreadModel[] => {
  const currentDepthThreads: ThreadModel[] = [];

  for (let index = 0; index < threads.length; index += 1) {
    const thread = threads[index];
    if (thread.depth < depth) break;

    if (thread.depth === depth) {
      currentDepthThreads.push({
        id: thread.id,
        content: thread.content,
        userDisplayName: thread.userDisplayName || '',
        uploadDate: thread.date,
        descendantsCount: Number(thread.children_count),
        children: mapThreadsRecursively(threads.slice(index + 1), depth + 1),
        likes: thread.likes,
      });
    }
  }

  return currentDepthThreads;
};

class CommentService {
  public static getByReaction = async (params: {
    reactionId: number;
    limit: number;
    page: number;
  }) => {
    try {
      const response: ApiResponse<
      Nullable<ApiCommentModel[]>
      > = await PublicApi.getCommentsByReaction({
        request: {
          limit: params.limit,
          page: params.page,
        },
        path: {
          id: params.reactionId,
        },
      });
      if (!response || !response.data) return null;
      const results = response?.data;

      const comments: CommentModel[] = results.map((result) => {
        return {
          id: result.id,
          content: result.content,
          userName: result.userDisplayName || '',
          userPortrait: result.userPhotoUrl || '',
          uploadDate: result.time,
        };
      });

      return comments;
    } catch (err) {
      return null;
    }
  };

  public static getByProduction = async (params: {
    productionId: number;
    limit: number;
    page: number;
    depth: number;
    sort: 'top' | 'oldest' | 'newest';
  }) => {
    try {
      const response: ApiResponse<
      Nullable<ApiThreadModel[]>
      > = await PublicApi.getCommentsByProduction({
        request: {
          limit: params.limit,
          page: params.page,
          depth: params.depth,
          sort: params.sort,
        },
        path: {
          id: params.productionId,
        },
      });

      if (!response || !response.data) return null;
      const results = response?.data;

      const comments: ThreadModel[] = mapThreadsRecursively(results, 0);

      return comments;
    } catch (err) {
      return null;
    }
  };
}

export default CommentService;
