import { useEffect } from 'react';
import { ReactionModel } from '../services/reaction-service';
import Services from '../services';
import usePagination from './use-pagination';
import usePreparedPages from './use-prepared-pages';
import useSinglePage from './use-single-page';
import usePagesAreLoaded from './use-pages-are-loaded';

const useTopReactions = ({
  pageNumber,
  pageLimit,
}: {
  pageNumber: number;
  pageLimit: number;
}) => {
  const [reactionPages, setReactionPage] = usePagination<ReactionModel>();
  const preparedReactionPages = usePreparedPages(reactionPages);
  const reactions = useSinglePage(preparedReactionPages);
  const pagesAreLoaded = usePagesAreLoaded({
    pageCount: pageNumber,
    pages: reactionPages,
  });
  const isLoading = !pagesAreLoaded;

  useEffect(() => {
    Services.Reaction.getTop({
      page: pageNumber,
      limit: pageLimit,
    }).then((results) => {
      setReactionPage({
        pageNumber,
        page: results,
      });
    });
  }, [pageNumber, pageLimit]);

  return [reactions, isLoading] as const;
};

export default useTopReactions;
