import React, { createContext, useState } from 'react';

const PopupContext = createContext<[boolean, (showPopup: boolean) => void]>([
  false,
  () => undefined,
]);

const PopupContextProvider: React.FC = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <PopupContext.Provider value={[showPopup, setShowPopup]}>
      {children}
    </PopupContext.Provider>
  );
};

export {
  PopupContext,
  PopupContextProvider,
};
