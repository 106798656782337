import { ArrayElement, Nullable } from '../utils/type-utils';
import useQueryParams from './use-query-params';
import { ArrayUtils } from '../utils';

const sortableParams = [
  'top',
  'oldest',
  'newest',
] as const;

type SortType = ArrayElement<typeof sortableParams>;

const getValidSortParam = (
  sortParam?: Nullable<string>,
): SortType | undefined => {
  return sortableParams.find((sortableParam) => (sortableParam === sortParam));
};

const useProductionDiscussionSort = () => {
  const [{ sort, ...otherParams }, setQueryParams] = useQueryParams();

  const onChangeSort = (newSort: string) => {
    const validSort = getValidSortParam(newSort);
    if (!validSort) return;

    setQueryParams({
      ...otherParams,
      sort: validSort,
    });
  };

  return [
    getValidSortParam(ArrayUtils.getFirstIfArrayOrSelf(sort)) || 'top',
    onChangeSort,
  ] as const;
};

export default useProductionDiscussionSort;
