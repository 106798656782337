import React from 'react';
import styled from 'styled-components/macro';
import Icons from '../icons';

const Container = styled.div``;

const ReactionCardOptions: React.FC = () => {
  return (
    <Container>
      <Icons.ReactionOptions />
    </Container>
  );
};

export default ReactionCardOptions;
