import React from 'react';
import styled from 'styled-components/macro';
import { Dialog } from '@material-ui/core';
import StylelessButton from '../styleless-button';
import Icons from '../icons';
import StylelessAnchor from '../styleless-anchor';
import Config from '../../config';

const Container = styled(Dialog)`
  & .MuiPaper-root {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #F3F1FD;
    max-width: 22rem;
    border-radius: 16px;
  }
`;

const CloseButton = styled(StylelessButton)`
  align-self: flex-end;
  color: #FFF;
  margin-bottom: 1rem;
`;

const AppLink = styled(StylelessAnchor)`
  margin-bottom: 1.5rem;
  align-self: center;
`;

const BadgeLink = styled(StylelessAnchor)`
  align-self: center;
  display: flex;
  justify-content: center;
`;

const PvAppStoreIcon = styled(Icons.AppStoreIcon)`
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  height: 100%;
`;

const PvAppStoreBadge = styled(Icons.AppStoreBadgeBlack)`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0.5rem;

  width: 12rem;
  height: 100%;
`;

const IosPopup: React.FC<{
  onClose: () => void;
  open: boolean;
}> = ({ onClose, open }) => {
  return (
    <Container
      onClose={onClose}
      open={open}
    >
      <CloseButton
        onClick={onClose}
      >
        <Icons.CloseGray />
      </CloseButton>
      <AppLink
        href={Config.MobileApp.iosLink}
      >
        <PvAppStoreIcon />
      </AppLink>
      <BadgeLink
        href={Config.MobileApp.iosLink}
      >
        <PvAppStoreBadge />
      </BadgeLink>
    </Container>
  );
};

export default IosPopup;
