import React from 'react';
import styled from 'styled-components/macro';
import StylelessLink from '../styleless-link';

const Option: React.FC<{
  selected: boolean;
  path: string;
  className?: string;
}> = ({ path, children, className }) => {
  return (
    <StylelessLink to={path} className={className}>
      {children}
    </StylelessLink>
  );
};

const ContentSelectorOption = styled(Option)`
  display: flex;
  color: ${(props) => (props.selected ? '#795DE7' : '#000')};
`;

export default ContentSelectorOption;
