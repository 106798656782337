import { useState, useEffect, useRef } from 'react';
import { Nullable } from '../utils/type-utils';

const useIsClamped = <T extends Element>() => {
  const [height, setHeight] = useState<Nullable<number>>(null);
  const [isClamped, setIsClamped] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    const clientHeight = ref?.current?.clientHeight || 0;
    const scrollHeight = ref?.current?.scrollHeight || 0;
    setIsClamped(clientHeight < scrollHeight);
  }, [height]);

  const onHeightChange = (newHeight: number) => {
    setHeight(newHeight);
  };

  return [isClamped, ref, onHeightChange] as const;
};

export default useIsClamped;
