import React from 'react';
import Dropdown from '../dropdown';

const StringDropdown: React.FC<
Omit<React.ComponentProps<typeof Dropdown>, 'onChange' | 'value'> & {
  value?: string;
  onChange?: (value: string) => void;
}
> = ({
  onChange,
  children,
  ...dropdownProps
}) => {
  const handleChange = (changeValue: string) => {
    onChange?.(changeValue);
  };

  return (
    <Dropdown
      onChange={handleChange}
      {...dropdownProps}
    >
      {children}
    </Dropdown>
  );
};

export default StringDropdown;
