import styled from 'styled-components/macro';

const StylelessButton = styled.button`
  padding: 0px;
  margin: 0px;
  border: none;
  background: none;
`;

export default StylelessButton;
