import React from 'react';
import { useHistory, generatePath } from 'react-router';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components/macro';
import { ProductionCard } from '../../components';
import { usePosterHeight } from '../../hooks';
import { Paths } from '../../routes';
import { Nilable } from '../../utils/type-utils';

const Wrapper = styled.div`
  display: flex;
  box-shadow: 0px 16px 16px rgba(64, 48, 141, 0.16);
  border-radius: 16px;
  background-color: #FFFFFF;
`;

const WhatsPoppinProductionCard: React.FC<{
  productionId: number;
  poster: string;
  type: string;
  genres: string[];
  title: string;
  reactionsCount: number;
  score?: Nilable<number>;
  totalRatings: Nilable<number>;
  className?: string;
}> = ({
  productionId,
  poster,
  type,
  genres,
  title,
  reactionsCount,
  score,
  totalRatings,
  className,
}) => {
  const [posterHeight, onWidthChange] = usePosterHeight();
  const history = useHistory();

  return (
    <Wrapper className={className}>
      <ReactResizeDetector
        handleWidth
        onResize={onWidthChange}
      >
        <ProductionCard.Container>
          <ProductionCard.Media
            $height={posterHeight}
          >
            <ProductionCard.MediaRoundPoster
              src={poster}
              title={title}
              onClick={() => {
                history.push(generatePath(Paths.ProductionDetails, {
                  id: productionId,
                }));
              }}
              $height={posterHeight}
            />
          </ProductionCard.Media>
          <ProductionCard.Score
            score={score}
          />
          <ProductionCard.BodyWrapper>
            <ProductionCard.Categorization
              type={type}
              genres={genres}
            />
            <ProductionCard.Title
              productionId={productionId}
              title={title}
            />
            <ProductionCard.Reactions
              reactionsCount={reactionsCount}
            />
            <ProductionCard.Spacer />
            <ProductionCard.Footer
              totalRatings={totalRatings}
            >
              <ProductionCard.Button
                onClick={() => {
                  history.push(generatePath(Paths.ProductionDetails, {
                    id: productionId,
                  }));
                }}
              >
                Details
              </ProductionCard.Button>
            </ProductionCard.Footer>
          </ProductionCard.BodyWrapper>
        </ProductionCard.Container>
      </ReactResizeDetector>
    </Wrapper>
  );
};

export default styled(WhatsPoppinProductionCard)<{ $compact?: boolean }>`
  width: ${(props) => (props.$compact ? '20.625rem' : '100%')};
`;
