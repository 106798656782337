export const Base = '/';

export const ClientApp = '/app';
export const Home = `${ClientApp}/home`;

export const WhatsPoppin = `${Home}/whats-poppin`;
export const TopReactions = `${Home}/top-reactions`;

export const Discover = `${ClientApp}/discover`;

export const Watchlist = `${ClientApp}/watchlist`;

export const ProductionDetails = `${ClientApp}/productions/:id`;
export const ProductionDiscussion = `${ClientApp}/productions/:id/discussion`;
export const ReactionDetails = `${ClientApp}/reactions/:id`;

export const About = `${ClientApp}/about`;

export const Auth = `${ClientApp}/auth`;

export const Default = '*';
