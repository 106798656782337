import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import Icons from '../icons';
import { Nilable } from '../../utils/type-utils';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ScoreIcon = styled.div`
  margin-right: 0.25rem;
`;

const ScorePercent = styled.div`
  font-weight: 800;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.0625rem;
`;

const ProductionCardScoreLabel: React.FC<{
  score?: Nilable<number>;
}> = ({ score }) => {
  const isPoppin = () => {
    return score && score >= 70;
  };

  const isEmpty = () => {
    return _.isNil(score);
  };

  const icon = () => {
    if (isEmpty()) return (<Icons.PopcornPopGray />);

    return isPoppin()
      ? (<Icons.PopcornPop />)
      : (<Icons.PopcornSeed />);
  };

  return (
    <Container>
      <ScoreIcon>
        {icon()}
      </ScoreIcon>
      <ScorePercent>
        {!_.isNil(score) ? `${score} %` : '-- %'}
      </ScorePercent>
    </Container>
  );
};

export default ProductionCardScoreLabel;
