import React from 'react';
import styled from 'styled-components/macro';
import { ThreadModel } from '../../services/comment-service';
import { Icons, StylelessButton } from '../../components';
import { useFormattedCommentTime, useMobileAppPopup } from '../../hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  &:first-child {
    margin-top: 0.75rem;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const MoreContainer = styled(Container)`
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  margin-bottom: 0.5rem;
  word-break: break-word;
`;

const DisplayName = styled.div`
  font-weight: 800;
  word-break: break-word;
`;

const BulletIcon = styled(Icons.Bullet)`
  margin: 0rem 0.25rem;
`;

const UploadTime = styled.div`
  color: #767676;
`;

const Footer = styled.div`
  background-color: rgba(239, 239, 239, 0.4);
  border-radius: 24px;
  display: flex;
  align-items: center;

  padding: 0.5rem 0.75rem;
`;

const SubThreads = styled.div`
  margin-top: 1rem;
  display: flex;
`;

const ThreadBorder = styled.div<{
  $depth: number;
}>`
  width: 2px;
  background-color: ${({ $depth }) => ($depth === 0
    ? '#311C69'
    : $depth === 1
      ? '#40308D'
      : $depth === 2
        ? '#795DE7'
        : $depth === 3
          ? '#B08DE9'
          : '#F3F1FD'
  )};
  flex-shrink: 0;
`;

const ThreadList = styled.div`
  flex-grow: 1;
`;

const MoreButton = styled(StylelessButton)`
  background-color: #F3F1FD;
  border-radius: 16px;
  padding: 0.5rem 1rem;
  flex-grow: 0;
  flex-shrink: 0;

  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #795DE7;
`;

const Rate = styled(StylelessButton)`
  display: flex;
  align-items: center;
  margin-right: 2.25rem;
`;

const RateUpIcon = styled(Icons.ThreadRateUp)`
  margin-right: 0.75rem;
`;

const RateText = styled.div`
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #767676;
`;

const RateDownIcon = styled(Icons.ThreadRateDown)`
  margin-left: 0.75rem;
`;

const Reply = styled(StylelessButton)`
  display: flex;
  align-items: center;
`;

const ReplyIcon = styled(Icons.ThreadReply)`
  margin-right: 0.5rem;
`;

const ReplyText = styled.div`
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #767676;
`;

const MoreIcon = styled(Icons.ThreadMore)`
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const ProductionDiscussionCommentThread: React.FC<{
  thread: ThreadModel;
  depth: number;
  className?: string;
}> = ({ thread, depth, className }) => {
  const formattedTime = useFormattedCommentTime(thread.uploadDate);
  const { openPopup } = useMobileAppPopup();

  return (
    <Container className={className}>
      <Header>
        <DisplayName>
          {thread.userDisplayName}
        </DisplayName>
        <BulletIcon />
        <UploadTime>
          {formattedTime}
        </UploadTime>
      </Header>
      <Message>
        {thread.content}
      </Message>
      <Footer>
        <Rate onClick={openPopup}>
          <RateUpIcon />
          <RateText>
            {thread.likes || 'Vote'}
          </RateText>
          <RateDownIcon />
        </Rate>
        <Reply onClick={openPopup}>
          <ReplyIcon />
          <ReplyText>
            Reply
          </ReplyText>
        </Reply>
        <Spacer />
        <MoreIcon />
      </Footer>
      {thread.children.length > 0 && (
        <SubThreads>
          <ThreadBorder $depth={depth} />
          {depth >= 4 ? (
            <MoreContainer>
              <MoreButton>
                {`${thread.descendantsCount} more replies`}
              </MoreButton>
            </MoreContainer>
          ) : (
            <ThreadList>
              {thread.children.map((subThread) => (
                <ProductionDiscussionCommentThread
                  key={subThread.id}
                  thread={subThread}
                  depth={depth + 1}
                />
              ))}
            </ThreadList>
          )}
        </SubThreads>
      )}
    </Container>
  );
};

export default ProductionDiscussionCommentThread;
