import React from 'react';
import styled from 'styled-components/macro';
import {
  ReactionsGrid,
  DetailSection,
  Spinner,
} from '../../components';
import {
  useProductionIdFromPath,
  useProduction,
  useReactionsFromProduction,
  useSeasonEpisodeFilter,
} from '../../hooks';
import ProductionDetailsFilters from './production-details-filters';
import ProductionDetailsProductionCard from './production-details-production-card';

const LoadingProduction = styled(Spinner)`
  margin: 2rem 0rem;
`;

const Gutter = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  }
`;

const LoadingIcon = styled(Spinner)`
  margin-bottom: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

const RelatedContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 0rem 1rem;

  @media (min-width: 768px) {
    padding: 0rem;
  }
`;

const ReactionsHeader = styled.div`
  display: flex;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 800;
  margin-top: 1.75rem;

  @media (min-width: 768px) {
    padding-left: 3rem;
    height: 5rem;
    background-color: #FFF;
    margin-top: 0rem;
    box-shadow: 0px 4px 8px rgba(64, 48, 141, 0.08);
  }
`;

const ReactionsGridContainer = styled.div`
  padding-top: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2rem;
  }

  @media (min-width: 1200px) {
    padding-right: 8.5rem;
  }
`;

const ProductionDetails: React.FC = () => {
  const productionId = useProductionIdFromPath();
  const production = useProduction(productionId);
  const [
    selectedSeason,
    selectedEpisodeNumber,
    onSeasonChange,
    onEpisodeChange,
  ] = useSeasonEpisodeFilter(production?.seasons);
  const [
    reactions,
    isLoadingReactions,
    onReactionIndexViewed,
  ] = useReactionsFromProduction({
    productionId,
    seasonNumber: selectedSeason?.seasonNumber || null,
    episodeNumber: selectedSeason && selectedEpisodeNumber,
  });

  return (
    <Container>
      <DetailSection>
        {production ? (
          <>
            <Gutter>
              <ProductionDetailsProductionCard
                production={production}
              />
            </Gutter>
            {production.type === 'Show' ? (
              <ProductionDetailsFilters
                seasons={production.seasons}
                selectedSeason={selectedSeason}
                selectedEpisodeNumber={selectedEpisodeNumber}
                onSeasonChange={onSeasonChange}
                onEpisodeChange={onEpisodeChange}
              />
            ) : null}
          </>
        ) : (
          <LoadingProduction />
        )}
      </DetailSection>
      <RelatedContent>
        <ReactionsHeader>
          Newest Reactions
        </ReactionsHeader>
        <ReactionsGridContainer>
          <ReactionsGrid.ResponsiveContainer>
            {reactions.map((reaction, index) => (
              <ReactionsGrid.Card
                key={reaction.id}
                reactionId={reaction.id}
                video={reaction.video}
                reactorName={reaction.reactorName}
                reactorPicture={reaction.reactorPicture}
                productionName={reaction.productionName}
                uploadDate={reaction.uploadDate}
                likes={reaction.likes}
                liked={reaction.liked}
                caption={reaction.caption}
                comments={reaction.comments}
                thumbnail={reaction.thumbnail}
                episode={reaction.episode}
                season={reaction.season}
                type={reaction.type}
                format={reaction.format}
                giphyId={reaction.giphyId}
                onVisibleOnce={() => onReactionIndexViewed(index)}
              />
            ))}
          </ReactionsGrid.ResponsiveContainer>
          {isLoadingReactions && (
            <LoadingIcon />
          )}
        </ReactionsGridContainer>
      </RelatedContent>
    </Container>
  );
};

export default ProductionDetails;
