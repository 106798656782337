import React from 'react';
import styled from 'styled-components/macro';
import { generatePath } from 'react-router';
import StylelessLink from '../styleless-link';
import { Paths } from '../../routes';

const Container = styled(StylelessLink)`
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  flex-shrink: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const ReactionCardPortrait: React.FC<{
  reactionId: number;
  src: string;
}> = ({
  src,
  reactionId,
}) => {
  return (
    <Container
      to={generatePath(Paths.ReactionDetails, {
        id: reactionId,
      })}
    >
      <Image
        src={src}
        alt=""
      />
    </Container>
  );
};

export default ReactionCardPortrait;
