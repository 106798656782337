import styled from 'styled-components/macro';

const PostCommentButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #795DE7;
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.125rem;

  border-radius: 7px;
  border: none;

  &:disabled {
    opacity: 0.5;
  }
`;

export default PostCommentButton;
