import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components/macro';
import { useGridWidth } from '../../hooks';
import { ArrayUtils } from '../../utils';
import { ProductionModel } from '../../services/production-service';
import WhatsPoppinProductionsRow from './whats-poppin-productions-row';
import { Spinner } from '../../components';

const PREFERRED_WIDTHS = {
  extraLarge: 310,
  large: 310,
  default: 290,
};

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProductionsGrid: React.FC<{
  gridWidth: number;
  productions: ProductionModel[];
}> = ({ gridWidth, productions }) => {
  return (
    <Grid>
      {ArrayUtils.chunk(productions, gridWidth).map((productionsChunk, index) => {
        return (
          <WhatsPoppinProductionsRow
            key={`chunk-${index}`}
            width={gridWidth}
            productions={productionsChunk}
          />
        );
      })}
    </Grid>
  );
};

const WhatsPoppinProductionsGrid: React.FC<{
  productions: ProductionModel[] | null;
}> = ({ productions }) => {
  const [gridWidth, onWidthChange] = useGridWidth(PREFERRED_WIDTHS);

  return (
    <ReactResizeDetector
      handleWidth
      onResize={onWidthChange}
    >
      {productions && gridWidth ? (
        <ProductionsGrid gridWidth={gridWidth} productions={productions} />
      ) : (
        <Spinner />
      )}
    </ReactResizeDetector>
  );
};

export default WhatsPoppinProductionsGrid;
