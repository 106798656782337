import styled from 'styled-components/macro';

const CommentInput = styled.textarea`
  padding: 0.75rem 1rem;
  resize: none;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  appearance: none;

  margin-bottom: 0.5rem;

  &::placeholder {
    color: #C6C6C6;
    opacity: 1;
  }

  &:disabled {
    background-color: #FFF;
    opacity: 1;
  }
`;

export default CommentInput;
