import styled from 'styled-components/macro';
import _ from 'lodash';

const ProductionCardMedia = styled.div<{
  $height?: number;
}>`
  width: 100%;
  height: ${(props) => (_.isNil(props.$height)
    ? '100%'
    : `${props.$height}px`
  )};
`;

export default ProductionCardMedia;
