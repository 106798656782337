import React from 'react';
import styled from 'styled-components/macro';
import ProductionCardScoreLabel from './production-card-score-label';
import { Nilable } from '../../utils/type-utils';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 0rem;
`;

const Overlay = styled.div`
  position: absolute;
  width: 5.25rem;
  height: 2.5rem;
  top: -1.5rem;
  left: calc(50% - 2.625rem);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #FFF;
  border-radius: 0.75rem;
`;

const ProductionCardScore: React.FC<{
  score?: Nilable<number>;
}> = ({
  score,
}) => {
  return (
    <Container>
      <Overlay>
        <ProductionCardScoreLabel score={score} />
      </Overlay>
    </Container>
  );
};

export default ProductionCardScore;
