import { Drawer, DrawerProps } from '@material-ui/core';
import styled from 'styled-components/macro';

const ClientAppSidebar = styled(Drawer).attrs((): Partial<DrawerProps> => ({
  classes: {
    paper: 'drawerPaper',
  },
}))`
  flex-shrink: 0;
  width: 13rem;
  color: #767676;

  & .drawerPaper {
    width: 13rem;
    background-color: #000;
    color: #767676;
    border-right: none;
  }
`;

export default ClientAppSidebar;
