import React from 'react';
import { Icons } from '../../components';
import { Paths } from '../../routes';

interface MenuItem {
  title: string;
  getPath: (currentPath: string) => string;
  redirect: boolean;
  renderIcon: (selected: boolean) => JSX.Element;
}

export const menuItems: MenuItem[] = [
  {
    title: 'Home',
    getPath: () => Paths.Home,
    redirect: true,
    renderIcon: (selected) => (
      selected
        ? <Icons.SelectedHome />
        : <Icons.Home />
    ),
  },
  {
    title: 'Discover',
    getPath: (currentPath) => `${currentPath}?showPopup=true`,
    redirect: true,
    renderIcon: (selected) => (
      selected
        ? <Icons.SelectedDiscover />
        : <Icons.Discover />
    ),
  },
  {
    title: 'Watchlist',
    getPath: (currentPath) => `${currentPath}?showPopup=true`,
    redirect: true,
    renderIcon: (selected) => (
      selected
        ? <Icons.SelectedWatchlist />
        : <Icons.Watchlist />
    ),
  },
  {
    title: 'About',
    getPath: () => Paths.About,
    redirect: true,
    renderIcon: (selected) => (
        selected
            ? <Icons.SelectedAbout />
            : <Icons.About />
    ),
  },
];
