import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  font-size: 0.687rem;
  font-weight: 800;
  line-height: 1rem;
  letter-spacing: 0.062rem;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1rem;
`;

const ProductionCardReactions: React.FC<{
  reactionsCount: number;
  className?: string;
}> = ({
  reactionsCount,
  className,
}) => {
  return (
    <Container className={className}>
      {`${reactionsCount} Reactions`}
    </Container>
  );
};

export default ProductionCardReactions;
