import { useState, useEffect } from 'react';
import _ from 'lodash';
import usePageVisited from './use-page-visited';

const useShowSpoilerWarning = () => {
  const pageVisited = usePageVisited();
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (!_.isNil(pageVisited)) {
      setShowWarning(!pageVisited);
    }
  }, [pageVisited]);

  const closeWarning = () => {
    setShowWarning(false);
  };

  return [showWarning, closeWarning] as const;
};

export default useShowSpoilerWarning;
