import { PublicApi } from './apis';
import { ApiResponse } from './apis/apis';

export type LiveEventModel = {
  id: number;
  name: string;
  description: string;
};

type ApiLiveEventModel = {
  id: number;
  name: string;
  description: string;
};

class LiveEventService {
  public static getOngoing = async () => {
    try {
      const response: ApiResponse<ApiLiveEventModel | null> = await PublicApi.getOngoingLiveEvent();
      const liveEvent: LiveEventModel | null = response?.data || null;

      return liveEvent;
    } catch (err) {
      return null;
    }
  };
}

export default LiveEventService;
