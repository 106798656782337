import React from 'react';
import pvLogo from '../../assets/images/pv-logo.svg';

const PvLogo: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <img className={className} src={pvLogo} alt="pv-logo" />
  );
};

export default PvLogo;
