import { useState } from 'react';
import { Nullable } from '../utils/type-utils';

const useFurthestIndexViewed = () => {
  const [
    furthestIndexViewed,
    setFurthestIndexViewed,
  ] = useState<Nullable<number>>(null);

  const onIndexViewed = (viewedIndex: number) => {
    if (
      !furthestIndexViewed
      || viewedIndex > furthestIndexViewed
    ) setFurthestIndexViewed(viewedIndex);
  };

  const resetFurthestIndexViewed = () => {
    setFurthestIndexViewed(null);
  };

  return [furthestIndexViewed, onIndexViewed, resetFurthestIndexViewed] as const;
};

export default useFurthestIndexViewed;
