import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import StylelessButton from '../styleless-button';

const ButtonWrapper = styled(StylelessButton)`
  width: 100%;
`;

const Poster = styled.img`
  width: 100%;
  object-fit: cover;
`;

const ProductionCardMediaPoster: React.FC<{
  src?: string;
  title?: string;
  onClick?: () => void;
  className?: string;
}> = ({
  src,
  title,
  onClick,
  className,
}) => {
  return onClick ? (
    <ButtonWrapper
      onClick={onClick}
    >
      <Poster
        src={src}
        title={title}
        className={className}
      />
    </ButtonWrapper>
  ) : (
    <Poster
      src={src}
      title={title}
      className={className}
    />
  );
};

export default styled(ProductionCardMediaPoster)<{
  $height?: number;
}>`
  ${(props) => (_.isNil(props.$height)
    ? 'height: 100%;'
    : `height: ${props.$height}px;`)}
`;
