import styled from 'styled-components/macro';

const ReactionCardFooter = styled.div`
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.125rem;

  padding-top: 0.5rem;
  padding-bottom: 1rem;
`;

export default ReactionCardFooter;
