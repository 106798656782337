import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const StylelessLink = styled(Link)`
  color: unset;
  text-decoration: none;

  :hover, :focus {
    color: unset;
    text-decoration: none;
  }
`;

export default StylelessLink;
