import React from 'react';
import styled from 'styled-components/macro';
import { ReactionsGrid, Spinner } from '../../components';
import { useTopReactionsPage, useTopReactions, useFurthestIndexViewed } from '../../hooks';
import TopReactionsHeader from './top-reactions-header';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 576px) {
    padding: 2rem 3rem;
  }

  @media (min-width: 992px) {
    padding: 2rem 3rem;
    padding-right: 8.5rem;  
  }
`;

const TopReactions: React.FC = () => {
  const [furthestIndexViewed, onIndexViewed] = useFurthestIndexViewed();
  const [pageNumber, pageLimit] = useTopReactionsPage(furthestIndexViewed);
  const [reactions, isLoadingReactions] = useTopReactions({
    pageNumber,
    pageLimit,
  });

  return (
    <Container>
      <TopReactionsHeader />
      <Content>
        <ReactionsGrid.ResponsiveContainer>
          {reactions.map((reaction, index) => (
            <ReactionsGrid.Card
              key={reaction.id}
              reactionId={reaction.id}
              video={reaction.video}
              reactorName={reaction.reactorName}
              reactorPicture={reaction.reactorPicture}
              productionName={reaction.productionName}
              uploadDate={reaction.uploadDate}
              likes={reaction.likes}
              liked={reaction.liked}
              caption={reaction.caption}
              comments={reaction.comments}
              thumbnail={reaction.thumbnail}
              episode={reaction.episode}
              season={reaction.season}
              type={reaction.type}
              format={reaction.format}
              giphyId={reaction.giphyId}
              onVisibleOnce={() => onIndexViewed(index)}
            />
          ))}
        </ReactionsGrid.ResponsiveContainer>
        {isLoadingReactions && (
          <Spinner />
        )}
      </Content>
    </Container>
  );
};

export default TopReactions;
