import { useEffect, useState } from 'react';
import { Nullable } from '../utils/type-utils';

type Season = {
  episodes: number;
  seasonNumber: number;
};

const useSeasonEpisodeFilter = (seasons?: Season[]) => {
  const [selectedSeasonNumber, setSelectedSeasonNumber] = useState(0);
  const [selectedSeason, setSelectedSeason] = useState<Nullable<Season>>(null);
  const [selectedEpisodeNumber, setSelectedEpisodeNumber] = useState(0);

  useEffect(() => {
    const matchingSeason = seasons?.find((season) => (
      selectedSeasonNumber && selectedSeasonNumber === season.seasonNumber
    )) || null;

    setSelectedSeason(matchingSeason);
  }, [seasons, selectedSeasonNumber]);

  const onSeasonChange = (seasonNumber: number) => {
    setSelectedEpisodeNumber(0);
    setSelectedSeasonNumber(seasonNumber);
  };

  const onEpisodeChange = (episodeNumber: number) => {
    setSelectedEpisodeNumber(episodeNumber);
  };

  return [
    selectedSeason,
    selectedEpisodeNumber,
    onSeasonChange,
    onEpisodeChange,
  ] as const;
};

export default useSeasonEpisodeFilter;
