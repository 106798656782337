export default Object.freeze({
  getProductionById: {
    method: 'get',
    path: 'productions/:id',
  },
  getTrending: {
    method: 'get',
    path: '/productions/trending',
  },
  getProductionVideo: {
    method: 'get',
    path: 'productions/:id/video',
  },
  getOngoingLiveEvent: {
    method: 'get',
    path: '/live-events/ongoing',
  },
  getTopReactions: {
    method: 'get',
    path: '/reactions/top',
  },
  getReactionsByProduction: {
    method: 'get',
    path: 'productions/:id/reactions',
  },
  getReactionById: {
    method: 'get',
    path: 'reactions/:id',
  },
  getCommentsByReaction: {
    method: 'get',
    path: 'reactions/:id/comments',
  },
  getCommentsByProduction: {
    method: 'get',
    path: 'productions/:id/comments',
  },
} as const);
