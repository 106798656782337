import { useState } from 'react';
import { Nullable } from '../utils/type-utils';
import Services from '../services';

const useProductionYoutubeVideo = (productionId: number) => {
  const [youtubeVideoId, setYoutubeVideoId] = useState<Nullable<string>>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadYoutubeVideo = () => {
    setIsLoading(true);
    Services.Production.getYoutubeVideo(productionId).then((videoId) => {
      setYoutubeVideoId(videoId);
      setIsLoading(false);
    });
  };
  return [youtubeVideoId, isLoading, loadYoutubeVideo] as const;
};

export default useProductionYoutubeVideo;
