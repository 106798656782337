import React, {
  useState,
  useRef,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import {
  Icons,
  StylelessButton,
  ContentSelector,
} from '../../components';
import { Nullable } from '../../utils/type-utils';
import { useMobileAppPopup } from '../../hooks';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #FFF;

  padding: 0rem 1.5rem;
  height: 5rem;

  box-shadow: 0px 4px 8px rgba(64, 48, 141, 0.08);

  /* TODO: Try to find a better way to wrap header bar */
  @media (max-width: 320px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: 576px) {
    padding: 0rem 3rem;
  }

  @media (min-width: 992px) {
    padding-right: 8.5rem;  
  }
`;

const ContentButton = styled(StylelessButton)`
  font-size: 1.5rem;
  font-weight: 900;
`;

const Arrow = styled(Icons.ChevronDown)`
  margin-left: 0.25rem;
  margin-bottom: 0.375rem;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const Search = styled.div`
`;

const WhatsPoppinHeader: React.FC = () => {
  const isMediumScreen = useMediaQuery({ query: '(min-width: 768px)' });
  const [anchorElement, setAnchorElement] = useState<Nullable<Element>>(null);
  const anchorRef = useRef(null);
  const { openPopup } = useMobileAppPopup();

  return (
    <Container>
      <ContentButton
        type="button"
        onClick={() => setAnchorElement(anchorRef?.current || null)}
      >
        What's Poppin'
        <Arrow ref={anchorRef} />
      </ContentButton>
      <ContentSelector
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
        selectedOption="whats-poppin"
      />
      <Filters>
        <Search>
          <StylelessButton onClick={openPopup}>
            {isMediumScreen ? (
              <Icons.SearchInput />
            ) : (
              <Icons.SearchMobile />
            )}
          </StylelessButton>
        </Search>
      </Filters>
    </Container>
  );
};

export default WhatsPoppinHeader;
