import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Nullable } from '../utils/type-utils';

const useProductionDetailsReactionsPage = (furthestIndexViewed: Nullable<number>) => {
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);

  useEffect(() => {
    const nextPageOffset = 6;
    const nextPageBoundary = (page * perPage) - nextPageOffset;

    if (
      !_.isNil(furthestIndexViewed)
      && furthestIndexViewed > nextPageBoundary
    ) setPage(page + 1);
  }, [furthestIndexViewed, page, perPage]);

  const resetPage = () => {
    setPage(1);
  };

  return [page, perPage, resetPage] as const;
};

export default useProductionDetailsReactionsPage;
