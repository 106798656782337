import React from 'react';
import styled from 'styled-components/macro';
import { Icons, StylelessAnchor } from '../../components';
import Config from '../../config';
import giphyAttribution from '../../assets/images/giphy-attribution.png';

const Container = styled.div`
  padding: 2rem 1rem;
`;

const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 800;
`;

const Description = styled.div`
  margin-bottom: 1.5rem;
`;

const Terms = styled(StylelessAnchor)`
  display: flex;
  color: #795DE7 !important;

  padding-bottom: 0.5rem;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 0.5rem;
`;

const Policy = styled(StylelessAnchor)`
  display: flex;
  color: #795DE7 !important;

  padding-bottom: 0.5rem;
  border-bottom: 1px solid #CCCCCC;

  margin-bottom: 2.5rem;
`;

const Tmdb = styled.div`
`;

const Disclaimer = styled.div`
`;

const TmdbLogo = styled(Icons.Tmdb)`
  display: flex;
  margin-bottom: 1rem;
`;

const GiphyLogo = styled.img`
  margin-top: 2rem;
`;

const Home: React.FC = () => {
  return (
    <Container>
      <Title>
        About Us
      </Title>
      <Description>
        We're fostering community for all kinds of content!
        &nbsp;We're all stuck at home trying to figure out what to watch next.
        <b>&nbsp;PopViewers is the platform that helps you find what to watch next</b>
        , invites you to react to the TV shows and movies you've watched,
        &nbsp;and encourages you to
        <b>&nbsp;share the experience with fellow content-lovers</b>
        .
      </Description>
      <Terms href={Config.LandingPage.termsPage}>
        Terms of Use
      </Terms>
      <Policy href={Config.LandingPage.policyPage}>
        Privacy Policy
      </Policy>
      <Tmdb>
        <Disclaimer>
          <TmdbLogo />
          <div>
            This product uses the TMDb API but is not endorsed or certified by TMDb.
          </div>
        </Disclaimer>
      </Tmdb>
      <GiphyLogo src={giphyAttribution} title="giphy-logo" />
    </Container>
  );
};

export default Home;
