import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { ArrayUtils } from '../../utils';
import { ProductionModel } from '../../services/production-service';
import WhatsPoppinEmptySlot from './whats-poppin-empty-slot';
import WhatsPoppinProductionCard from './whats-poppin-production-card';

const Gutter = styled.div`
  width: 2rem;
  flex-shrink: 0;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 2rem;
`;

const WhatsPoppinProductionsRow: React.FC<{
  width: number;
  productions: ProductionModel[];
}> = ({
  width,
  productions,
}) => {
  return (
    <Container>
      {ArrayUtils.mapN(width, (index) => {
        const production = ArrayUtils.findByIndex(productions, index);

        return (
          <Fragment
            key={`${production ? production.id : 0}-${index}`}
          >
            {production ? (
              <WhatsPoppinProductionCard
                productionId={production.id}
                poster={production.poster}
                type={production.type}
                genres={production.genres}
                title={production.title}
                reactionsCount={production.reactionsCount}
                score={production.score}
                totalRatings={production.totalRatings}
                $compact={width === 1}
              />
            ) : (
              <WhatsPoppinEmptySlot />
            )}
            {index < width - 1 && (
              <Gutter />
            )}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default WhatsPoppinProductionsRow;
