import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const useOnVisibleOnce = (onVisible?: () => void) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) onVisible?.();
  }, [inView]);

  return [ref] as const;
};

export default useOnVisibleOnce;
