import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { Dialog } from '@material-ui/core';
import { StylelessButton } from '../../components';
import { Paths } from '../../routes';

const Container = styled(Dialog)`
  & .MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    background-color: #F3F1FD;
    max-width: 24rem;
    border-radius: 16px;
  }
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-weight: 700;

  margin-bottom: 1.5rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ConfirmButton = styled(StylelessButton)`
  padding: 0.687rem 2rem !important;
  border: 1.5px solid #795de7 !important;
  border-radius: 20px !important;

  background-color: #795de7;

  text-align: center !important;
  font-weight: 800 !important;
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
  color: #FFF;

  &:disabled {
    border-color: rgba(0, 0, 0, 0.4) !important;
    cursor: not-allowed !important;
  }
`;

const AuthIosPopup: React.FC<{
  onClose: () => void;
  open: boolean;
}> = ({ onClose, open }) => {
  const history = useHistory();

  return (
    <Container
      onClose={onClose}
      open={open}
    >
      <Message>
        To reset your password,
        please open the link on a mobile device
        with PopViewers installed.
      </Message>
      <Footer>
        <ConfirmButton
          onClick={() => {
            history.push(Paths.Home);
          }}
        >
          OK
        </ConfirmButton>
      </Footer>
    </Container>
  );
};

export default AuthIosPopup;
