import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components/macro';
import { Icons, StylelessLink } from '../../components';
import ClientAppSidebar from './client-app-sidebar';
import ClientAppMenu from './client-app-menu';
import { Paths } from '../../routes';

const Gutter = styled.div`
  height: 4rem;
`;

const Sidebar = styled(ClientAppSidebar)`
`;

const Menu = styled(ClientAppMenu)`
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  color: #795DE7;
  background-color: #000;
  height: 4rem;
  position: fixed;
  left: 0rem;
  right: 0rem;

  z-index: 2;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;

  z-index: -1;
`;

const LogoLink = styled(StylelessLink)`
  width: 2rem;
  flex-shrink: 0;
`;

const Logo = styled(Icons.PvLogo)`
  width: 100%;
`;

const ClientAppNavigationMobile: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Sidebar
        variant="temporary"
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Menu onClickItem={() => setIsOpen(false)} />
      </Sidebar>
      <Header>
        <IconButton
          color="inherit"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icons.MenuIcon />
        </IconButton>
        <LogoWrapper>
          <LogoLink to={Paths.Home}>
            <Logo />
          </LogoLink>
        </LogoWrapper>
      </Header>
      <Gutter />
    </>
  );
};

export default ClientAppNavigationMobile;
