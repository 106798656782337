import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { PopupContext } from '../contexts/popup-context';
import useQueryParams from './use-query-params';

const useMobileAppPopup = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useContext(PopupContext);
  const [{ showPopup, ...otherParams }, setQueryParams] = useQueryParams();

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [pathname]);

  const closePopup = () => {
    setOpen(false);

    if (showPopup) {
      setQueryParams({
        ...otherParams,
        showPopup: undefined,
      });
    }
  };

  const openPopup = () => {
    setOpen(true);
  };

  return {
    open: open || !!showPopup,
    closePopup,
    openPopup,
  };
};

export default useMobileAppPopup;
