import { PublicApi } from './apis';
import { ApiResponse } from './apis/apis';
import { Nilable } from '../utils/type-utils';

type SeasonModel = {
  episodes: number;
  seasonNumber: number;
};

export type ProductionModel = {
  id: number;
  poster: string;
  type: 'Movie' | 'Show';
  genres: string[];
  title: string;
  score: Nilable<number>;
  reactionsCount: number;
  commentsCount?: number;
  seasons?: SeasonModel[];
  totalRatings: Nilable<number>;
};

type ApiProductionModel = {
  id: number;
  image: string;
  title: string;
  genres: string[];
  overview: string;
  type: ProductionType;
  communityScore: Nilable<number>;
  reactionsCount: number;
  commentsCount?: number;
  seasons?: SeasonModel[];
  totalRatings: Nilable<number>;
};

type ApiVideoModel = {
  key: string;
  type: 'Trailer' | 'Teaser' | 'Clip';
};

enum ProductionType {
  Movie = 1,
  Show = 2,
}

class ProductionService {
  public static getById = async (productionId: number) => {
    try {
      const response: ApiResponse<ApiProductionModel> = await PublicApi.getProductionById({
        path: {
          id: productionId,
        },
      });

      const fetchedProduction = response?.data || null;
      const production: ProductionModel | null = fetchedProduction && {
        id: fetchedProduction.id,
        poster: fetchedProduction.image,
        type: fetchedProduction.type === ProductionType.Movie ? 'Movie' : 'Show',
        genres: fetchedProduction.genres,
        title: fetchedProduction.title,
        score: fetchedProduction.communityScore,
        reactionsCount: fetchedProduction.reactionsCount,
        commentsCount: fetchedProduction.commentsCount,
        seasons: fetchedProduction.seasons,
        totalRatings: fetchedProduction.totalRatings,
      };

      return production;
    } catch (err) {
      return null;
    }
  };

  public static getTrending = async () => {
    try {
      const response: ApiResponse<ApiProductionModel[]> = await PublicApi.getTrending();

      const trendingProductions: ProductionModel[] = response?.data.map((production) => ({
        id: production.id,
        poster: production.image,
        type: production.type === ProductionType.Movie ? 'Movie' : 'Show',
        genres: [...production.genres],
        title: production.title,
        score: production.communityScore,
        reactionsCount: production.reactionsCount,
        commentsCount: production.commentsCount,
        seasons: production.seasons,
        totalRatings: production.totalRatings,
      })) || [];

      return trendingProductions;
    } catch (err) {
      return null;
    }
  };

  public static getYoutubeVideo = async (productionId: number) => {
    try {
      const response: ApiResponse<ApiVideoModel | null> = await PublicApi.getProductionVideo({
        path: {
          id: productionId,
        },
      });

      return response?.data?.key || null;
    } catch (err) {
      return null;
    }
  };
}

export default ProductionService;
