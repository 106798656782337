import { AxiosResponse } from 'axios';
import { generatePath } from 'react-router';
import { mapObjectValues } from '../../utils/object-utils';

interface Endpoint {
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  path: string;
}
type Endpoints = { [key: string]: Endpoint };
type ApiParameters = {
  request?: RequestParameters;
  path?: PathParameters;
};
type ApiCall = (path: string, params: RequestParameters) => Promise<AxiosResponse>;
type ApiClient = {
  get?: ApiCall;
  post?: ApiCall;
  put?: ApiCall;
  patch?: ApiCall;
  delete?: ApiCall;
};
type ApiResponse<T = any> = AxiosResponse<T> | undefined;
type ApiCreator = <T extends Endpoints> (
  client: ApiClient,
  endpoints: T,
) => {
  [U in keyof T]: (params?: ApiParameters) => Promise<ApiResponse>;
};
type PrimitiveParameter = string | number | boolean;
type ParameterValue =
  PrimitiveParameter |
  PrimitiveParameter[] |
  null |
  undefined;
type RequestParameters = {
  [key: string]: ParameterValue | RequestParameters | RequestParameters[];
  [key: number]: ParameterValue | RequestParameters | RequestParameters[];
};
type PathParameters = {
  [key: string]: string | number | boolean | undefined;
};

const createApi: ApiCreator = (client, endpoints) => {
  return mapObjectValues(endpoints, ([, endpoint]) => {
    return async (params?: ApiParameters) => {
      const path = generatePath(endpoint.path, {
        ...params?.path,
      });

      return client[endpoint.method]?.(path, {
        ...params?.request,
      });
    };
  });
};

export { createApi };
export type {
  ApiClient,
  ApiCall,
  ApiResponse,
  RequestParameters,
};
