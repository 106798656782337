import React from 'react';
import styled from 'styled-components/macro';
import ProductionDiscussionCommentThread from './production-discussion-comment-thread';
import {
  useProductionIdFromPath,
  useProductionDiscussionSort,
} from '../../hooks';
import useCommentsFromProduction from '../../hooks/use-comments-from-production';
import { Spinner } from '../../components';
import ProductionDiscussionCommentBox from './production-discussion-comment-box';
import ProductionDiscussionCommentHeader from './production-discussion-comment-header';

const Container = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0px 16px 16px rgba(64, 48, 141, 0.16);

  @media (min-width: 992px) {
    margin-right: 2rem;
  }
`;

const LoadingComments = styled(Spinner)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const DiscussionThread = styled(ProductionDiscussionCommentThread)`
  &&& {
    margin-bottom: 1.5rem;
  }
  padding-right: 1rem;
`;

const Threads = styled.div`
`;

const ProductionDiscussionCommentSection: React.FC<{
  commentsCount?: number;
  className?: string;
}> = ({
  commentsCount,
  className,
}) => {
  const productionId = useProductionIdFromPath();
  const [sort] = useProductionDiscussionSort();
  const threads = useCommentsFromProduction({
    productionId,
    sort,
  });

  return (
    <Container className={className}>
      <ProductionDiscussionCommentHeader commentsCount={commentsCount} />
      <ProductionDiscussionCommentBox />
      <Threads>
        {threads ? threads.map((thread) => (
          <DiscussionThread
            key={thread.id}
            thread={thread}
            depth={0}
          />
        )) : (
          <LoadingComments />
        )}
      </Threads>
    </Container>
  );
};

export default ProductionDiscussionCommentSection;
