import _ from 'lodash';
import { Nilable, NonNilable } from '../utils/type-utils';

type Page<PageItem> = PageItem[];
type UnpreparedPage<PageItem> = Nilable<Page<PageItem>>;
type PreparedPage<PageItem> = NonNilable<Page<PageItem>>;

const takePreparedPages = <PageItem>(
  unpreparedPages: UnpreparedPage<PageItem>[],
) => {
  const preparedPages: PreparedPage<PageItem>[] = [];

  unpreparedPages.forEach((unpreparedPage) => {
    if (_.isNil(unpreparedPage)) return preparedPages;

    preparedPages.push(unpreparedPage);
  });

  return preparedPages;
};

const usePreparedPages = <PageItem>(
  unpreparedPages: UnpreparedPage<PageItem>[],
): PreparedPage<PageItem>[] => {
  const preparedPages = takePreparedPages(unpreparedPages);
  return preparedPages;
};

export default usePreparedPages;
