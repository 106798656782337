import React from 'react';
import styled from 'styled-components/macro';
import { CircularProgress } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  justify-content: center;
  color: #795DE7;
`;

const Spinner: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <Container className={className}>
      <CircularProgress color="inherit" />
    </Container>
  );
};

export default Spinner;
