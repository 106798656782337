import React from 'react';
import ReactionCard from '../reaction-card';
import { useOnVisibleOnce } from '../../hooks';
import { ReactionType } from '../../services/reaction-service';
import { Nilable } from '../../utils/type-utils';

const ReactionsGridCard: React.FC<{
  reactionId: number;
  video: string;
  reactorName: string;
  reactorPicture: string;
  productionName: string;
  uploadDate: string;
  likes: number;
  liked: boolean;
  comments: number;
  thumbnail: string;
  caption?: string | null;
  episode?: number | null;
  season?: number | null;
  type: ReactionType;
  format: Nilable<string>;
  giphyId: Nilable<string>;
  onVisibleOnce?: () => void;
}> = ({
  reactionId,
  video,
  reactorName,
  reactorPicture,
  productionName,
  uploadDate,
  likes,
  liked,
  comments,
  thumbnail,
  caption,
  episode,
  season,
  type,
  format,
  giphyId,
  onVisibleOnce,
}) => {
  const [ref] = useOnVisibleOnce(onVisibleOnce);

  return (
    <ReactionCard.RoundContainer ref={ref}>
      <ReactionCard.Header>
        <ReactionCard.Portrait
          src={reactorPicture}
          reactionId={reactionId}
        />
        <ReactionCard.About
          reactionId={reactionId}
          reactorName={reactorName}
          uploadDate={uploadDate}
        />
        <ReactionCard.Options />
      </ReactionCard.Header>
      <ReactionCard.Media
        video={video}
        thumbnail={thumbnail}
        type={type}
        format={format}
        giphyId={giphyId}
      />
      <ReactionCard.RatingSection>
        <ReactionCard.Production
          name={productionName}
          season={season}
          episode={episode}
        />
        <ReactionCard.RatingThumb liked={liked} />
      </ReactionCard.RatingSection>
      <ReactionCard.Divider />
      <ReactionCard.Caption
        caption={caption}
        reactionId={reactionId}
      />
      <ReactionCard.Footer>
        <ReactionCard.Likes likes={likes} />
        <ReactionCard.Comments
          reactionId={reactionId}
          comments={comments}
        />
      </ReactionCard.Footer>
    </ReactionCard.RoundContainer>
  );
};

export default ReactionsGridCard;
